import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Layout from '../../components/Layout'
import ModalImage from 'react-modal-image'
import { StaticImage } from 'gatsby-plugin-image'

import approvalWorkflows from '../../resources/images/usecases/kafka-governance/approval-workflows.svg'
import auditLogs from '../../resources/images/usecases/kafka-governance/audit-logs.svg'
import auditsIntoSplunk from '../../resources/images/usecases/kafka-governance/audits-into-splunk.svg'
import Spacing from '../../components/partials/Spacing'
import buildGitOps from '../../resources/images/usecases/kafka-governance/build-git-ops.svg'
import connections from '../../resources/images/usecases/kafka-governance/connections.png'
import connectorMgt from '../../resources/images/usecases/kafka-governance/connector-mgt.svg'
import consumerOffsetMng from '../../resources/images/usecases/kafka-governance/consumer-offset-mng.svg'
import crudAccess from '../../resources/images/usecases/kafka-governance/crud-access.svg'
import dataCatalog from '../../resources/images/usecases/kafka-governance/data-catalog.svg'
import dataLineage from '../../resources/images/usecases/kafka-governance/data-lineage.svg'
import dataMasking from '../../resources/images/usecases/kafka-governance/data-masking.svg'
import fineGrainedPermissions from '../../resources/images/usecases/kafka-governance/fine-grained-permissions.svg'
import monitorInfrastuctureFlowAlerting from '../../resources/images/usecases/kafka-governance/monitor-infrastucture-flow-alerting.svg'
import powerfulUI from '../../resources/images/usecases/kafka-governance/powerful-UI.svg'
import quotaACLMgt from '../../resources/images/usecases/kafka-governance/quota-ACL-mgt.svg'
import secureRoleBasedSecurity from '../../resources/images/usecases/kafka-governance/secure-role-based-security.svg'
import sqlEngine from '../../resources/images/usecases/kafka-governance/sql-engine.svg'
import topicMgt from '../../resources/images/usecases/kafka-governance/topic-mgt.svg'
import userMgt from '../../resources/images/usecases/kafka-governance/user-mgt.svg'

const kafkaGovernance = () => {
  const data = useStaticQuery(graphql`
    query GovernanceSEO {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "Usecases_Governance" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <StaticImage
        className="home-fixed-top"
        src="../../resources/images/homepage/hero_lens.svg"
        placeholder="blurred"
        alt="Home Lenses"
      />
      <section className="pt-5 pb-5 mt-5 ">
        <div className="container-1 pb-5 ipad-margin-top">
          <div className="d-flex flex-wrap mt-3">
            <div className="col-md-6 pt-0">
              <p className="title breadcrumb-text pt-4">
                <a href="/usecases/" className="h4-like">
                  Use cases
                </a>{' '}
                <span className="h4-like">/ Governance & Self-service </span>
              </p>
              <h1>
                Get production-ready with Apache Kafka governance & self-service
              </h1>
              <div className="pr-5 pt-3 pb-2">
                <p className="hero-description-dark">
                  <b> Apache Kafka monitoring is just the beginning. </b>
                  Imagine you could create a healthy, safe multi-tenant Kafka
                  environment for teams to build real-time apps and flows,
                  without worrying about breaching compliance or causing an
                  outage?
                </p>
              </div>
              <div className="roll-button">
                <a className="bg-red text-white" href="/start/">
                  <span data-hover="Get started">Get started</span>
                </a>
              </div>
            </div>
            <div className="col-md-6 pt-0 ipad-margin-top">
              <StaticImage
                className="img-fluid mx-auto d-block mobile-margin-top-half w-75"
                src="../../resources/images/usecases/kafka-governance/hero-governance-new.svg"
                placeholder="tracedSVG"
                alt="Kafka Governance"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap">
            <div className="col-md-6 col-12">
              <h2>
                “Can I protect Kafka & give self-service access to engineers?”
              </h2>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-md-11 col-12">
              <p>
                Kafka should be your business’s central nervous system, but not
                without addressing the elephant in the room: Data governance,
                data protection, data masking, access controls and auditing are
                all non-existent.
              </p>
              <p>
                The pressure is on for you to deliver new data products to
                market. But a wild west of streaming data inaccessible to the
                right people is slowing you down and turning your platform team
                into a dependency.
              </p>
              <p>
                Applying the right rules, guardrails and self-service
                administration to Kafka means <b> higher productivity</b>, more
                POCs move to production, and more apps move to market.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5 mt-3">
        <div className="container-1">
          <div className="d-flex flex-wrap ">
            <div className="pt-1 text-center">
              <div className="img-fluid w-25 pt-5 pl-5 pr-5 pb-3 mx-auto">
                <StaticImage
                  src="../../resources/images/clients/viseca-logo-black.svg"
                  placeholder="tracedSVG"
                  alt="Viseca"
                />
              </div>
              <p className="testimonial text-center m-auto w-50 mobile-w-100 mobile-padding-left mobile-padding-right">
                &ldquo;I believe the biggest value of Lenses.io is in governance
                support, at operational process and information security level,
                which makes it an ideal solution for enterprises for whom scale
                is a matter of serious attention.&rdquo;
              </p>
              <p
                className="testimonial-by mobile-padding-left mobile-padding-right"
                style={{ color: '#696969' }}
              >
                Dario Carnelli, Governance Expert (Isaca Certified)
              </p>
              <div className="mt-4">
                <a href="/customers/viseca/" className=" primary-text">
                  Read their story <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-lg-4 col-md-8 col-12">
              <p className="h2-like cera-pro text-center">
                What self service features should I have?{' '}
              </p>
            </div>
            <div className="col-sm-9 col-12 mr-5 mobile-margin-reset">
              <div className="d-flex flex-wrap justify-content-center">
                <div className="col-sm-3 col-6">
                  <img
                    className="usecase-feature"
                    src={userMgt}
                    alt="User management"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-700">
                    User mgt
                  </p>
                </div>
                <div className="col-sm-3 col-6">
                  <img
                    className="usecase-feature"
                    src={quotaACLMgt}
                    alt="Quota and ACL management"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-700">
                    quota & acl mgt
                  </p>
                </div>
                <div className="col-sm-3 col-6">
                  <img
                    className="usecase-feature"
                    src={dataMasking}
                    alt="Data masking"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-700">
                    data masking
                  </p>
                </div>
                <div className="col-sm-3 col-6">
                  <img
                    className="usecase-feature"
                    src={consumerOffsetMng}
                    alt="Consumer & Offset Management"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-700">
                    consumer & offset mgt
                  </p>
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-center">
                <div className="col-sm-3 col-6">
                  <img
                    className="usecase-feature"
                    src={topicMgt}
                    alt="Topic Management"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-700">
                    topic mgt
                  </p>
                </div>
                <div className="col-sm-3 col-6">
                  <img
                    className="usecase-feature"
                    src={connectorMgt}
                    alt="Connector Management"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-700">
                    connector mgt
                  </p>
                </div>
                <div className="col-sm-3 col-6">
                  <img
                    className="usecase-feature"
                    src={fineGrainedPermissions}
                    alt="Fine grained permissions"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-700">
                    fine-grained permissions
                  </p>
                </div>
                <div className="col-sm-3 col-6">
                  <img
                    className="usecase-feature"
                    src={dataLineage}
                    alt="data lineage"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-700">
                    data lineage
                  </p>
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-center">
                <div className="col-sm-3 col-6">
                  <img
                    className="usecase-feature"
                    src={auditLogs}
                    alt="audit logs"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-700">
                    audit logs
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-1 mt-5 pt-5 mobile-padding-reset">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-md-6 col-12 text-center">
              <h2>How can I keep engineers productive on Kafka?</h2>
            </div>
          </div>
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-md-5 col-10 mobile-margin-top">
              <p className="hero-description-dark">
                Collect metadata from my data sources by providing a{' '}
                <a href="/blog/2020/07/data-dump-real-time-data-catalog-apache-kafka/">
                  {' '}
                  Data Catalog
                </a>
              </p>
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-6 col-12">
              <ModalImage
                className="w-100"
                small={dataCatalog}
                large={dataCatalog}
                alt="Data catalog"
              />
            </div>
          </div>
        </div>

        <div className="container-1 mt-5 pt-0 mobile-padding-reset">
          <div className="d-flex flex-wrap pt-4 align-items-center">
            <div className="col-md-6 col-12">
              <ModalImage
                className="w-100"
                small={powerfulUI}
                large={powerfulUI}
                alt="Powerful UI"
              />
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-5 col-10">
              <p className="hero-description-dark">
                Offer a powerful UI to view applications and data.
              </p>
            </div>
          </div>
        </div>

        <div className="container-1 mt-5 pt-0 mobile-padding-reset">
          <div className="d-flex flex-wrap pt-4 align-items-center">
            <div className="col-md-5 col-10 p-0">
              <div className="col">
                <p className="hero-description-dark">
                  Equip engineers with a SQL engine to query and process data in
                  real-time.
                </p>
              </div>
            </div>
            <div className="offset-1"></div>
            <div className="col-md-6 col-12">
              <ModalImage
                className="w-100 "
                small={sqlEngine}
                large={sqlEngine}
                alt="SQL engine"
              />
            </div>
          </div>
        </div>

        <div className="container-1 mt-5 pt-0 mobile-padding-reset">
          <div className="d-flex flex-wrap pt-4 align-items-center">
            <div className="col-md-6 col-12">
              <ModalImage
                className="w-100"
                small={crudAccess}
                large={crudAccess}
                alt="CRUD Access"
              />
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-5 col-10">
              <p className="hero-description-dark">
                Provide CRUD access to manage Topics, Quotas, ACLs and Schemas.
              </p>
            </div>
          </div>
        </div>

        <div className="container-1 mt-5 pt-0 mobile-padding-reset">
          <div className="d-flex flex-wrap pt-4 align-items-center">
            <div className="col-md-5 col-10 p-0">
              <div className="col">
                <p className="hero-description-dark">
                  Give engineers an API and SDKs to integrate into their CI/CD
                  toolchain and practice GitOps.
                </p>
              </div>
            </div>
            <div className="offset-1"></div>
            <div className="col-md-6 col-12">
              <ModalImage
                className="w-100 "
                small={buildGitOps}
                large={buildGitOps}
                alt="Build GitOps"
              />
            </div>
          </div>
        </div>

        <div className="container-1 mt-5 pt-0 mobile-padding-reset">
          <div className="d-flex flex-wrap pt-4 align-items-center">
            <div className="col-md-6 col-12">
              <ModalImage
                className="w-100"
                small={connections}
                large={connections}
                alt="data operations"
              />
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-5 col-10">
              <p className="hero-description-dark">
                Integrate data operations with engineering tools such as Slack,
                Pagerduty and Splunk.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mt-5 pt-5 mobile-padding-reset">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-md-6 col-12 text-center">
              <h2>
                How do I protect the health of the platform and meet compliance?
              </h2>
            </div>
          </div>
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-md-5 col-10 mobile-margin-top">
              <p className="hero-description-dark">
                With
                <a href="/blog/2020/12/life-of-pii-data-masking-and-compliance-for-apache-kafka/">
                  {' '}
                  Role-based security,{' '}
                </a>
                protected and authenticated over SSO.
              </p>
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-6 col-12">
              <ModalImage
                className="w-100"
                small={secureRoleBasedSecurity}
                large={secureRoleBasedSecurity}
                alt="Role based Security"
              />
            </div>
          </div>
        </div>

        <div className="container-1 mt-5 pt-0 mobile-padding-reset">
          <div className="d-flex flex-wrap pt-4 align-items-center">
            <div className="col-md-6 col-12">
              <ModalImage
                className="w-100"
                small={monitorInfrastuctureFlowAlerting}
                large={monitorInfrastuctureFlowAlerting}
                alt="Real time Alerting"
              />
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-5 col-10">
              <p className="hero-description-dark">
                Using real-time alerting and monitoring if things do go wrong.
              </p>
            </div>
          </div>
        </div>

        <div className="container-1 mt-5 pt-0 mobile-padding-reset">
          <div className="d-flex flex-wrap pt-4 align-items-center">
            <div className="col-md-5 col-10 p-0">
              <div className="col">
                <p className="hero-description-dark">
                  By building approval processes to create topics.
                </p>
              </div>
            </div>
            <div className="offset-1"></div>
            <div className="col-md-6 col-12">
              <ModalImage
                className="w-100 "
                small={approvalWorkflows}
                large={approvalWorkflows}
                alt="Approval of workflows"
              />
            </div>
          </div>
        </div>

        <div className="container-1 mt-5 pt-0 mobile-padding-reset">
          <div className="d-flex flex-wrap pt-4 align-items-center">
            <div className="col-md-6 col-12">
              <ModalImage
                className="w-100"
                small={auditsIntoSplunk}
                large={auditsIntoSplunk}
                alt="Audits into Splunk"
              />
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-5 col-10">
              <p className="hero-description-dark">
                By integrating audit trails into your SIEM in real-time.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section className="bg-usecase-secure py-5 mobile-margin-top">
        <div className="container-1">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-7 col-12">
              <p className="f-16 text-center cera-pro text-uppercase text-white">
                {' '}
                Case Study
              </p>
              <div className="text-center pb-4 pt-4">
                <StaticImage
                  src="../../resources/images/usecases/secure/avanza-logo.svg"
                  placeholder="tracedSVG"
                  alt="Avanza"
                />
              </div>
              <h2 className="fw-900 text-center text-white h1-like">
                Leading the Swedish savings market by safely liberating Kafka
                data
              </h2>

              <p className="f-20 text-white text-center mb-0">
                How did investment platform Avanza win &gt; 1 million customers
                by enabling developers with a secure data platform?
              </p>

              <div className="mt-4 text-center">
                <a className="primary-text" href="/customers/avanza/">
                  Read more <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container-1 mt-5 pt-5 pb-5">
        <div className="d-flex flex-wrap justify-content-center">
          <div className="col-md-8 text-center col-12 roll-button">
            <h2 className="paragraph-title pb-3">
              {' '}
              Enjoy operational excellence with Apache Kafka governance
            </h2>

            <a className="bg-red text-white " href="/start/?s=monitor">
              <span data-hover="Start">Start</span>
            </a>
            <br />
            <br />
            <a href="/contact-us/" className=" primary-text">
              Or speak with a Kafka expert <i className="red-pointer-right"></i>
            </a>
          </div>
        </div>
      </div>
      <section className="home-bg-banner">
        <div className="container-1 align-items-center d-flex flex-wrap h-100 mobile-margin-reset">
          <div className="offset-sm-1 offset-0"></div>
          <div className="col-sm-4 col-12 ">
            <div className=" mobile-margin-right-0 mobile-padding-reset text-white">
              <div className="pb-3">
                <span className="text-white fw-500 text-uppercase d-sm-block d-none">
                  Whitepaper
                  {/* <span
                      className="f-12 fw-500 px-1 py-1 ml-3 bg-white rounded"
                      data-reactid="615"
                      style={{ color: 'black' }}
                    >
                      25 Sept 2020
                    </span> */}
                </span>
              </div>
              <span>
                <p className="f-28 lh-30 text-white mb-0">
                  <strong>
                    {' '}
                    Real-time data governance: A reference architecture
                  </strong>
                </p>{' '}
                <div className="d-flex flex-wrap mt-3">
                  co-authored with{' '}
                  <div className="pl-3 img-fluid w-30">
                    <StaticImage
                      src="../../resources/images/clients/viseca-logo-white.svg"
                      placeholder="tracedSVG"
                      alt="Viseca logo"
                    />
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div className="offset-sm-3 offset-3"></div>
          <div className="col-sm-3 col-9">
            <div className=" roll-button">
              <a
                className="bg-white primary-text button-mobile-font-size downloadbutton"
                href="/resources/data-governance-kafka/"
              >
                <span data-hover="Download">Download</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default kafkaGovernance
